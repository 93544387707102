<template>
  <div>
    <div>
      <b-button
        variant="primary"
        size="sm"
        :disabled="loadingCount > 0"
        @click="fetchStatus"
      >
        Reload
      </b-button>
    </div>

    <table class="table table-google table-highlight mt-3">
      <thead>
        <tr>
          <th class="text-nowrap">Candle</th>
          <th class="text-nowrap">Total</th>
          <th class="text-nowrap">Exists</th>
          <th class="text-nowrap">Missing</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="3">
            <div class="font-bold">
              <span v-if="loadingCount > 0">
                Loading..
              </span>
            </div>
          </td>
        </tr>

        <tr v-for="candle in status" :key="candle.template.value">
          <td>{{ candle.template.text }}</td>
          <td>{{ candle.exists + candle.missing | format }}</td>
          <td>{{ candle.exists | format }}</td>
          <td>{{ candle.missing | format }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'CandleLayerCache',
  components: {},
  data() {
    return {
      loadingCount: 0,
      status: null,
    };
  },
  methods: {
    fetchStatus() {
      this.loadingCount++;
      this.$http
        .get('/layerCache/status')
        .then((res) => {
          this.status = res.body.status;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch status: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchStatus();
  },
};
</script>

<style lang="scss" scoped>
</style>
